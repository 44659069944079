import * as React from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Board from '../components/board/board';
import classNames from 'classnames';
import Events from '../components/events/events';
import FormContact from '../components/elements/formContact';
import Home from '../components/homepage/home';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import Missions from '../components/missions';
import Navbar from '../components/navbar';
import { Scrollspy } from '@makotot/ghostui';
import SEO from '../components/seo';
import SideBar from '../components/sideBar';
import Solutions1 from '../components/solutions/solution1';
import Solutions2 from '../components/solutions/solution2';
import Solutions3 from '../components/solutions/solution3';
import Team from '../components/team/team';

interface MenuItem {
  content: string;
  href: string;
}

const menuItems: MenuItem[] = [
  { content: 'nav.missions', href: 'missions' },
  { content: 'nav.solutions', href: 'solutions' },
  { content: 'nav.events', href: 'events' },
  { content: 'nav.team', href: 'team' },
  { content: 'nav.graph', href: 'graph' },
  { content: 'nav.contact', href: 'contact' }
];

function NavBarItems({ sectionRefs }: { sectionRefs: React.RefObject<Element>[] }) {
  return (
    <>
      <Scrollspy sectionRefs={sectionRefs}>
        {({ currentElementIndexInViewport }) => (
          <div className='flex items-center'>
            {menuItems.map((item, i) =>
              item.href === 'graph' ? (
                <Link className={classNames('p-2 px-4 whitespace-no-wrap menu-items text-center', { active: currentElementIndexInViewport === i })} to={item.href} key={item.href}>
                  <FormattedMessage id={item.content} />
                </Link>
              ) : (
                <AnchorLink
                  className={classNames('p-2 px-4 whitespace-no-wrap menu-items text-center', { active: currentElementIndexInViewport === i })}
                  href={`#${item.href}`}
                  key={item.href}
                >
                  <FormattedMessage id={item.content} />
                </AnchorLink>
              )
            )}
          </div>
        )}
      </Scrollspy>
    </>
  );
}

function SideBarMenu() {
  return (
    <SideBar>
      <input id='toggle' type='checkbox' style={{ zIndex: 999 }} />

      <label className='toggle-container' htmlFor='toggle' style={{ zIndex: 999 }}>
        <span className='button button-toggle' style={{ zIndex: 999 }} />
      </label>
      <div className='nav' style={{ zIndex: 999 }}>
        {menuItems.map(item =>
          item.href === 'graph' ? (
            <Link className='nav-item' to={item.href} key={item.href}>
              <FormattedMessage id={item.content} />
            </Link>
          ) : (
            <a className='nav-item' href={`#${item.href}`} key={item.href}>
              <FormattedMessage id={item.content} />
            </a>
          )
        )}
      </div>
    </SideBar>
  );
}

export default function IndexPage() {
  const intl = useIntl();
  const sectionRefs = new Array(menuItems.length);
  for (let i = 0; i < sectionRefs.length; ++i) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    sectionRefs[i] = React.useRef(null);
  }

  return (
    <Layout
      navBar={layoutScrolledDown => (
        <Navbar scrolled={layoutScrolledDown}>
          <NavBarItems sectionRefs={sectionRefs} />
        </Navbar>
      )}
      sideBar={() => <SideBarMenu />}
    >
      <SEO lang={intl.locale} />
      <Home />
      <Missions ref={sectionRefs[menuItems.findIndex(item => item.href === 'missions')]} />
      <Solutions1 ref={sectionRefs[menuItems.findIndex(item => item.href === 'solutions')]} />
      <Solutions2 />
      <Solutions3 />
      <Events ref={sectionRefs[menuItems.findIndex(item => item.href === 'events')]} />
      <Team ref={sectionRefs[menuItems.findIndex(item => item.href === 'team')]} />
      <Board />
      <FormContact id='contact' ref={sectionRefs[menuItems.findIndex(item => item.href === 'contact')]} />
    </Layout>
  );
}
