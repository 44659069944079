import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { NNU } from '../../helpers';
import { Solution3SectionQuery } from '../../../graphqlTypes';
import { useIntl } from 'gatsby-plugin-react-intl';

import './solutions3.scss';

export default function Solution3() {
  const data = useStaticQuery<Solution3SectionQuery>(graphql`
    query Solution3Section {
      allStrapiSolution3Section {
        edges {
          node {
            locale
            description
            title
            rightFeatureText
            leftFeatureText
            descriptionAuthor
          }
        }
      }
      file(relativePath: { eq: "capgemini.png" }) {
        childImageSharp {
          gatsbyImageData(width: 192, layout: FIXED)
        }
      }
    }
  `);

  const intl = useIntl();
  const localeSolution3SectionData = NNU(data.allStrapiSolution3Section.edges.find(e => e.node.locale === intl.locale)).node;
  return (
    <section className='solution-3'>
      <h2>{localeSolution3SectionData.title}</h2>
      <q className='solution-3-description'>{localeSolution3SectionData.description}</q>
      <p className='solution-3-description-author'>{localeSolution3SectionData.descriptionAuthor}</p>

      <div className='feature-3'>
        <svg className='svg-icon-3' viewBox='0 0 20 20'>
          <path d='M10 2.262a6.33 6.33 0 00-6.322 6.322c0 2.129 1.105 4.126 2.905 5.291l.009 3.396a.469.469 0 00.714.397l6.338-3.922a.419.419 0 00.098-.08 6.331 6.331 0 002.581-5.082A6.332 6.332 0 0010 2.262zm3.109 10.707a.254.254 0 00-.044.037l-5.542 3.426-.006-2.594a.473.473 0 00-.191-.606c-1.675-.963-2.715-2.746-2.715-4.648a5.393 5.393 0 015.388-5.387 5.393 5.393 0 015.387 5.387 5.391 5.391 0 01-2.277 4.385z' />
        </svg>
        <h4 className='feature-text-3'>{localeSolution3SectionData.leftFeatureText}</h4>
        <br />
        <h4 className='feature-text-3'>{localeSolution3SectionData.rightFeatureText}</h4>
        <GatsbyImage alt='' image={NNU(data.file?.childImageSharp?.gatsbyImageData)} className='img-capgemini' />
      </div>
    </section>
  );
}
