import * as React from 'react';
import Button from './elements/button';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { X } from 'react-feather';

import './popup.scss';

export default function Popup({ actionText, children }: { actionText: string; children: React.ReactNode }) {
  const [showPopup, setshowPopup] = useState(false);

  return (
    <div>
      <div className='taCenter'>
        <Button
          onClick={() => {
            setshowPopup(!showPopup);
          }}
          icon={
            <svg className='w-4 h-4 mr-2 fill-current' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
              <path d='M17.919,4.633l-3.833,2.48V6.371c0-1-0.815-1.815-1.816-1.815H3.191c-1.001,0-1.816,0.814-1.816,1.815v7.261c0,1.001,0.815,1.815,1.816,1.815h9.079c1.001,0,1.816-0.814,1.816-1.815v-0.739l3.833,2.478c0.428,0.226,0.706-0.157,0.706-0.377V5.01C18.625,4.787,18.374,4.378,17.919,4.633 M13.178,13.632c0,0.501-0.406,0.907-0.908,0.907H3.191c-0.501,0-0.908-0.406-0.908-0.907V6.371c0-0.501,0.407-0.907,0.908-0.907h9.079c0.502,0,0.908,0.406,0.908,0.907V13.632zM17.717,14.158l-3.631-2.348V8.193l3.631-2.348V14.158z' />
            </svg>
          }
          className='transition duration-500 ease-in-out transform button-home z-2 hover:-translate-y-1 hover:scale-100 px-12 py-4'
        >
          {actionText}
        </Button>
      </div>

      {showPopup ? (
        <div className='Popup-Overlay'>
          <div
            className='Popup-Background'
            onClick={() => {
              setshowPopup(!showPopup);
            }}
          />
          <motion.div className='Popup-Inner' initial={{ opacity: 0.4, y: 100 }} animate={{ opacity: 1, y: 0 }}>
            <X
              className='Popup-Close'
              onClick={() => {
                setshowPopup(!showPopup);
              }}
            />
            {children}
          </motion.div>
        </div>
      ) : null}
    </div>
  );
}
