import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { TCN, useLocalStorage, useSessionStorage } from '../helpers';
import { FlaminemVideoQuery } from '../../graphqlTypes';
import Form from './elements/formEventVideo';
import { NNU } from '../helpers';
import { useIntl } from 'gatsby-plugin-react-intl';
import './popup.scss';

export default function FormVideoContainer() {
  const data = useStaticQuery<FlaminemVideoQuery>(graphql`
    query FlaminemVideo {
      allStrapiFlaminemVideo {
        edges {
          node {
            locale
            formTitle
            formButtonText
            video {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const localeFlaminemVideoData = NNU(data.allStrapiFlaminemVideo.edges.find(e => e.node.locale === intl.locale)).node;
  const [userCookieConsent] = useLocalStorage<boolean>('userCookieConsent');
  const [videoContactRegistered, setVideoContactRegistered] = useLocalStorage<boolean>('videoContactRegistered');
  const [bypassVideoContactForm, setBypassVideoContactForm] = useSessionStorage('bypassVideoContactForm', videoContactRegistered === true);

  return (
    <div className={TCN('layout-popup-container', 'video-selected', bypassVideoContactForm === true)}>
      {bypassVideoContactForm ? (
        <video className='container m-auto rounded shadow-lg outline-none appearance-none video' style={{ maxHeight: '100%', maxWidth: '100%' }} controls data-video={10}>
          <source src={localeFlaminemVideoData.video?.localFile?.publicURL || undefined} />
        </video>
      ) : (
        <div className='form-container-popup'>
          <h3 className='form-popup-title'>{localeFlaminemVideoData.formTitle}</h3>
          <Form
            contactRegistered={() => {
              if (userCookieConsent) {
                setVideoContactRegistered(true);
              }

              setBypassVideoContactForm(true);
            }}
            actionText={NNU(localeFlaminemVideoData.formButtonText)}
            formID={'video'}
          />
        </div>
      )}
    </div>
  );
}
