import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Check from './check';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HomeSectionQuery } from '../../../graphqlTypes';
import LogoAnimation from '../logoAnimation';
import { NNU } from '../../helpers';
import { useIntl } from 'gatsby-plugin-react-intl';

import './home.scss';

export default function Home() {
  const intl = useIntl();
  const data = useStaticQuery<HomeSectionQuery>(graphql`
    query HomeSection {
      allStrapiHomeSection {
        edges {
          node {
            locale
            title
            bulletPoints
          }
        }
      }
    }
  `);

  const localeData = NNU(data.allStrapiHomeSection.edges.find(e => e.node.locale === intl.locale)).node;
  const bannerText = intl.locale === 'fr' ? 'lauréate du ' : 'winner of the ';

  return (
    <section id='home'>
      <p className='banner'>
        <FontAwesomeIcon icon={faTrophy} />
        <span>
          {`Flaminem ${bannerText} `}
          <a target='_blank' rel='noreferrer' href='https://strapi.flaminem.com/uploads/Flaminem_Laureate_du_Financial_New_Tech_Challenge_1768231ba0.pdf'>
            Financial NewTech Challenge 2020
          </a>
        </span>
      </p>
      <div className='home'>
        <LogoAnimation />
        <h2 className='home-title'>{localeData.title}</h2>
        <ul className='mt-6 text-xl font-light check-title lg:text-2xl'>
          {localeData.bulletPoints?.split('\n').map((p, i) => (
            <li key={i} className='what-we-do'>
              <Check />
              {p}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
