import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import FormVideoContainer from '../formVideoContainer';
import { motion } from 'framer-motion';
import { NNU } from '../../helpers';
import Popup from '../popup';
import { Solution1SectionQuery } from '../../../graphqlTypes';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useInView } from 'react-intersection-observer';

import './solutions1.scss';

export default React.forwardRef<HTMLElement | null>(function Solution1(_, scrollSpyForwardRef) {
  const [inViewRef, inView] = useInView({ triggerOnce: true });
  const data = useStaticQuery<Solution1SectionQuery>(graphql`
    query Solution1Section {
      allStrapiSolution1Section {
        edges {
          node {
            locale
            title
            description
          }
        }
      }

      allStrapiSolution1Item {
        edges {
          node {
            locale
            icon {
              localFile {
                publicURL
              }
            }
            title
            description
          }
        }
      }

      allStrapiFlaminemVideo {
        edges {
          node {
            locale
            buttonText
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const localeSolution1SectionData = NNU(data.allStrapiSolution1Section.edges.find(e => e.node.locale === intl.locale)).node;
  const localeFlamimemVideoData = NNU(data.allStrapiFlaminemVideo.edges.find(e => e.node.locale === intl.locale)).node;

  return (
    <section id='solutions' className='solution-1' ref={scrollSpyForwardRef}>
      <h2 ref={inViewRef}>{localeSolution1SectionData.title}</h2>
      <p className='solution-1-description'>{localeSolution1SectionData.description}</p>
      <Popup actionText={NNU(localeFlamimemVideoData.buttonText)}>
        <FormVideoContainer />
      </Popup>
      {inView ? (
        <motion.div
          className='features-container'
          initial={{ opacity: 0.2, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.2,
            duration: 1
          }}
        >
          {data.allStrapiSolution1Item.edges
            .filter(item => item.node.locale === intl.locale)
            .map((item, i) => {
              const titleTokens = NNU(item.node.title?.trim().split(/\s+/));
              const lastTitleToken = titleTokens.pop();
              return (
                <div key={i} className='feature '>
                  <img className='svg-icon mx-auto' src={NNU(item.node.icon?.localFile?.publicURL)} alt={item.node.title!} />
                  <h3 className='feature-title'>
                    {titleTokens.join(' ')} <span className='span'>{lastTitleToken}</span>
                  </h3>
                  <h4 className='feature-text'>{item.node.description}</h4>
                </div>
              );
            })}
        </motion.div>
      ) : (
        <div style={{ height: 790 }} />
      )}
    </section>
  );
});
