import * as React from 'react';

import './home.scss';

const Check = () => (
  <svg className='w-5 h-5 mr-2 fill-current check-svg ' fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
    <path d='M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z' />
  </svg>
);

export default Check;
