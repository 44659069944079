import * as React from 'react';
import { FormattedDate, Link, useIntl } from 'gatsby-plugin-react-intl';
import { graphql, useStaticQuery } from 'gatsby';
import { EventSectionQuery } from '../../../graphqlTypes';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { NNU } from '../../helpers';
import ReactMarkdown from 'react-markdown';
import { useInView } from 'react-intersection-observer';

import './events.scss';

export default React.forwardRef<HTMLElement | null>(function Events(_, scrollSpyForwardRef) {
  const [inViewRef, inView] = useInView({ triggerOnce: true });
  const data = useStaticQuery<EventSectionQuery>(graphql`
    query EventSection {
      allStrapiEventSection {
        edges {
          node {
            locale
            title
            description
            navigateText
          }
        }
      }
      allStrapiEvent(sort: { fields: [eventDate], order: [DESC] }) {
        edges {
          node {
            slug
            eventDate
            eventContents {
              locale
              title
              place
              shortDescription
            }
            eventImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 392, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const localeEventSectionData = NNU(data.allStrapiEventSection.edges.find(e => e.node.locale === intl.locale)).node;

  return (
    <section id='events' ref={scrollSpyForwardRef}>
      <h2>{localeEventSectionData.title}</h2>
      <ReactMarkdown className='description'>{NNU(localeEventSectionData.description)}</ReactMarkdown>
      <div className='events-layout' ref={inViewRef}>
        {inView ? (
          data.allStrapiEvent.edges.map(({ node }, i) => {
            const localeContent = NNU(node.eventContents?.find(ec => ec?.locale === intl.locale));
            return (
              <motion.div
                key={i}
                initial={{ opacity: 0.4, y: -150 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  delay: 0.2,
                  duration: 0.5
                }}
              >
                <div className='event-card'>
                  <div className='container'>
                    <div className='relative'>
                      <Link to={`/${node.slug}`}>
                        <GatsbyImage
                          alt=''
                          image={NNU(node.eventImage?.localFile?.childImageSharp?.gatsbyImageData)}
                          className='event-image'
                          style={{ height: '12rem' }}
                          imgStyle={{ objectFit: 'contain' }}
                        />
                        <div className='event-overlay'>
                          <div className='text'>{localeEventSectionData.navigateText}</div>
                        </div>
                      </Link>
                      <div className='event-place'>
                        <span>{localeContent.place}</span>
                      </div>
                    </div>
                  </div>
                  <div className='event-contents'>
                    <div className='event-title-date-container'>
                      <div className='event-title '>{localeContent.title}</div>
                      <div className='event-date'>
                        <FormattedDate value={new Date(node.eventDate)} />
                      </div>
                    </div>
                    <p className='event-title-description'>{localeContent.shortDescription}</p>
                  </div>
                </div>
              </motion.div>
            );
          })
        ) : (
          <div style={{ height: 667 }} />
        )}
      </div>
    </section>
  );
});
