import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { BoardSectionQuery } from '../../../graphqlTypes';
import Carousel from 'react-elastic-carousel';
import { GatsbyImage } from 'gatsby-plugin-image';
import { NNU } from '../../helpers';
import { useIntl } from 'gatsby-plugin-react-intl';

import './board.scss';

export default function Board() {
  const data = useStaticQuery<BoardSectionQuery>(graphql`
    query BoardSection {
      allStrapiBoardSection {
        edges {
          node {
            locale
            title
          }
        }
      }
      allStrapiBoardMember {
        edges {
          node {
            name
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 128, layout: FIXED)
                }
              }
            }
            boardMemberDetails {
              locale
              position
              role
            }
          }
        }
      }
    }
  `);

  const carousel = React.useRef<Carousel | null>(null);

  const intl = useIntl();
  const localeBoardSectionData = NNU(data.allStrapiBoardSection.edges.find(e => e.node.locale === intl.locale)).node;

  return (
    <section id='board'>
      <h2>{localeBoardSectionData.title}</h2>
      <div className='carousel-container'>
        <Carousel ref={carousel} isRTL={false} focusOnSelect={true} itemsToScroll={1} itemsToShow={4} enableAutoPlay={true} autoPlaySpeed={5000} itemPadding={[0, 0, 0, 0]}>
          {data.allStrapiBoardMember.edges.map((boardMember, i) => {
            const localeBoardMemberDetailsData = NNU(boardMember.node.boardMemberDetails?.find(e => e?.locale === intl.locale));
            return (
              <div key={i} className='carousel-card'>
                <GatsbyImage image={NNU(boardMember.node.picture?.localFile?.childImageSharp?.gatsbyImageData)} alt='' className='carousel-img' />
                <h3 className='consultant-name'>{boardMember.node.name}</h3>
                <h5 className='consultant-job'>{localeBoardMemberDetailsData.position}</h5>
                <div className='block-color'>{localeBoardMemberDetailsData.role}</div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
}
