import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MissionSectionQuery } from '../../graphqlTypes';
import { NNU } from '../helpers';
import ReactMarkdown from 'react-markdown';
import { useIntl } from 'gatsby-plugin-react-intl';

import './missions.scss';

export default React.forwardRef<HTMLElement>(function Missions(_, ref) {
  const data = useStaticQuery<MissionSectionQuery>(graphql`
    query MissionSection {
      allStrapiMissionSection {
        edges {
          node {
            locale
            title
            content
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const localeData = NNU(data.allStrapiMissionSection.edges.find(e => e.node.locale === intl.locale)).node;

  return (
    <section id='missions' ref={ref} className='missions-container'>
      <h2>{localeData.title}</h2>
      <div className='missions-container-child'>
        <div className='icon-contains'>
          <Icon />
        </div>
        <div className='missions-contains'>
          <ReactMarkdown className='description missions-description'>{NNU(localeData.content)}</ReactMarkdown>
        </div>
      </div>
    </section>
  );
});

function Icon() {
  return (
    <svg className='missions-icon' xmlns='http://www.w3.org/2000/svg' width='1020' height='712' data-name='Layer 1' viewBox='0 0 1020 612'>
      <g clipPath='url(#clip0)'>
        <path d='M847.02 515.788H641.02V517.788H847.02V515.788Z' fill='#3F3D56' />
        <path d='M765.971 223.569V237.359L775.623 252.527L782.518 224.948L765.971 223.569Z' fill='#A0616A' />
        <path
          d='M764.592 100.846L772.132 98.6926C772.132 98.6926 783.897 100.846 783.897 114.635C783.897 128.424 786.655 183.581 786.655 183.581L783.897 215.296C783.897 215.296 788.034 216.675 786.655 220.812C785.276 224.948 782.518 234.601 781.139 233.222C779.76 231.843 779.76 229.085 779.76 229.085C779.76 229.085 757.697 233.222 759.076 229.085C760.455 224.948 760.455 215.296 761.834 215.296C763.213 215.296 760.455 173.928 760.455 173.928L764.592 100.846Z'
          fill='#E6E6E6'
        />
        <path d='M639.8 218.743L657.726 240.806L679.789 267.005L686.683 235.29L674.357 233.355L661.863 214.606L639.8 218.743Z' fill='#A0616A' />
        <path
          d='M684.615 211.159C684.615 211.159 663.931 222.19 677.72 296.652L681.857 371.114L684.615 493.837H712.193L717.709 372.493L734.256 295.273L760.455 364.219L750.803 486.943L788.034 492.458L800.444 354.567L778.381 252.527L761.834 223.569L758.387 215.985L684.615 211.159Z'
          fill='#2F2E41'
        />
        <path
          d='M688.752 489.701L684.615 502.111C684.615 502.111 679.099 524.174 698.404 521.416C717.709 518.658 710.814 506.248 710.814 506.248L709.435 489.701H688.752Z'
          fill='#2F2E41'
        />
        <path
          d='M752.182 485.564C752.182 485.564 748.045 499.353 748.045 506.248C748.045 513.142 750.803 516.142 750.803 516.142C750.803 516.142 829.401 521.416 828.022 514.521C826.643 507.627 818.37 504.869 818.37 504.869L799.065 500.732L785.276 489.701L757.698 482.806L752.182 485.564Z'
          fill='#2F2E41'
        />
        <path
          d='M720.467 66.3726C734.936 66.3726 746.666 54.6427 746.666 40.1732C746.666 25.7036 734.936 13.9737 720.467 13.9737C705.997 13.9737 694.267 25.7036 694.267 40.1732C694.267 54.6427 705.997 66.3726 720.467 66.3726Z'
          fill='#A0616A'
        />
        <path d='M739.772 51.204L743.908 89.814L734.256 107.74L710.814 81.541L708.056 59.478L739.772 51.204Z' fill='#A0616A' />
        <path
          d='M731.498 91.1931L709.871 73.9951L705.299 80.1617L670.826 93.9509L672.205 154.623C672.205 154.623 673.583 205.643 681.857 211.159L678.41 215.985L760.455 219.433V182.202L778.381 102.224L746.666 84.2985L742.719 78.7158L731.498 91.1931Z'
          fill='#E6E6E6'
        />
        <path
          d='M676.341 96.7088L674.333 92.5481L669.447 93.951C669.447 93.951 659.794 98.0877 652.9 116.014C646.005 133.94 626.7 173.928 626.7 173.928C626.7 173.928 621.185 180.823 625.321 187.717C629.458 194.612 633.595 209.78 633.595 209.78C633.595 209.78 629.458 208.401 632.216 213.917C634.974 219.433 634.974 224.948 643.247 223.569C651.521 222.19 668.068 218.054 666.689 213.917C665.31 209.78 663.931 202.886 661.173 202.886C658.415 202.886 654.279 182.202 654.279 182.202L657.036 171.17L677.72 146.35L676.341 96.7088Z'
          fill='#E6E6E6'
        />
        <path
          d='M723.225 20.8682C723.225 20.8682 719.088 20.8682 719.088 19.4893C719.088 19.4893 704.664 19.0491 698.776 25.4743C692.888 31.8995 694.267 22.2471 694.267 22.2471C694.267 22.2471 679.099 15.3525 691.509 8.45793L692.888 9.83685C692.888 9.83685 695.646 5.70009 705.299 4.32118L703.92 2.94226C703.92 2.94226 703.92 1.56334 710.814 1.56334C717.709 1.56334 735.635 -5.33126 737.014 9.83685C737.014 9.83685 748.045 5.7001 748.045 19.4893C748.045 33.2785 746.666 40.1731 746.666 40.1731C746.666 40.1731 743.908 33.2785 738.393 37.4152C732.877 41.552 723.225 20.8682 723.225 20.8682Z'
          fill='#2F2E41'
        />
        <path
          d='M106.501 473.55C165.32 473.55 213.002 468.623 213.002 462.545C213.002 456.467 165.32 451.54 106.501 451.54C47.682 451.54 0 456.467 0 462.545C0 468.623 47.682 473.55 106.501 473.55Z'
          fill='#E6E6E6'
        />
        <path d='M171.111 302.135H41.8903V462.596H171.111V302.135Z' fill='#E6E6E6' />
        <path d='M152.862 358.174H60.561V362.434H152.862V358.174Z' fill='white' />
        <path d='M152.862 373.084H60.561V377.344H152.862V373.084Z' fill='white' />
        <path d='M152.862 387.994H60.561V392.254H152.862V387.994Z' fill='white' />
        <path d='M152.862 402.904H60.561V407.164H152.862V402.904Z' fill='white' />
        <path
          d='M131.855 235.473C131.849 241.561 129.647 247.444 125.651 252.038C121.656 256.633 116.137 259.632 110.109 260.483C104.08 261.335 97.9461 259.982 92.8346 256.674C87.7232 253.366 83.9772 248.324 82.2851 242.475C80.5929 236.627 81.0683 230.364 83.6238 224.837C86.1793 219.311 90.6433 214.892 96.1955 212.393C101.748 209.894 108.015 209.483 113.847 211.235C119.678 212.987 124.681 216.784 127.937 221.929C130.497 225.982 131.856 230.679 131.855 235.473V235.473Z'
          fill='#E6E6E6'
        />
        <path
          d='M127.937 221.929L102.979 246.882C101.41 244.454 92.6712 233.168 92.6712 233.168C93.7912 232.036 94.9863 230.981 96.2485 230.01L103.495 239.672L125.014 218.154C126.105 219.317 127.084 220.581 127.937 221.929V221.929Z'
          fill='white'
        />
        <path
          d='M451.117 467.094C509.935 467.094 557.617 462.166 557.617 456.088C557.617 450.011 509.935 445.083 451.117 445.083C392.298 445.083 344.616 450.011 344.616 456.088C344.616 462.166 392.298 467.094 451.117 467.094Z'
          fill='#E6E6E6'
        />
        <path d='M515.727 295.679H386.506V456.14H515.727V295.679Z' fill='#E6E6E6' />
        <path d='M497.478 385.002H405.177V389.262H497.478V385.002Z' fill='white' />
        <path d='M497.478 399.912H405.177V404.172H497.478V399.912Z' fill='white' />
        <path d='M497.478 414.822H405.177V419.082H497.478V414.822Z' fill='white' />
        <path d='M497.478 429.732H405.177V433.992H497.478V429.732Z' fill='white' />
        <path d='M433.452 352.792H404.127C402.125 341.223 402.125 329.396 404.127 317.827H433.452C430.149 329.248 430.149 341.371 433.452 352.792V352.792Z' fill='white' />
        <path d='M499.608 321.101H445.647V324.651H499.608V321.101Z' fill='white' />
        <path d='M499.608 333.882H445.647V337.432H499.608V333.882Z' fill='white' />
        <path d='M499.608 346.662H445.647V350.212H499.608V346.662Z' fill='white' />
        <path d='M499.608 359.442H445.647V362.992H499.608V359.442Z' fill='white' />
        <path
          d='M476.471 229.849C476.465 235.937 474.262 241.82 470.267 246.414C466.272 251.009 460.753 254.008 454.724 254.859C448.696 255.711 442.562 254.359 437.45 251.05C432.339 247.742 428.593 242.7 426.901 236.851C425.209 231.003 425.684 224.74 428.239 219.213C430.795 213.687 435.259 209.268 440.811 206.769C446.364 204.27 452.631 203.859 458.462 205.611C464.294 207.363 469.297 211.16 472.553 216.305C475.113 220.358 476.472 225.054 476.471 229.849V229.849Z'
          fill='#E6E6E6'
        />
        <path
          d='M472.553 216.305L447.595 241.258C446.026 238.83 437.287 227.544 437.287 227.544C438.407 226.412 439.602 225.357 440.864 224.386L448.111 234.048L469.63 212.529C470.721 213.693 471.7 214.957 472.553 216.305Z'
          fill='white'
        />
        <path
          d='M280.781 535.575C349.715 535.575 405.598 529.801 405.598 522.678C405.598 515.554 349.715 509.78 280.781 509.78C211.847 509.78 155.965 515.554 155.965 522.678C155.965 529.801 211.847 535.575 280.781 535.575Z'
          fill='#3F3D56'
        />
        <path d='M355.671 337.533H204.227V525.59H355.671V337.533Z' fill='#435bc8' />
        <path d='M334.036 377.474H225.862V382.467H334.036V377.474Z' fill='white' />
        <path d='M334.036 394.949H225.862V399.941H334.036V394.949Z' fill='white' />
        <path d='M334.036 412.423H225.862V417.416H334.036V412.423Z' fill='white' />
        <path d='M334.036 429.897H225.862V434.89H334.036V429.897Z' fill='white' />
        <path d='M334.036 447.372H225.862V452.364H334.036V447.372Z' fill='white' />
        <path d='M308.241 465.678H251.657V485.649H308.241V465.678Z' fill='white' />
        <path
          d='M318.491 256.725C318.482 265.875 315.172 274.714 309.168 281.618C303.165 288.523 294.871 293.029 285.811 294.309C276.752 295.589 267.535 293.556 259.853 288.585C252.172 283.614 246.543 276.037 244 267.248C241.457 258.459 242.172 249.047 246.012 240.742C249.852 232.438 256.56 225.798 264.904 222.043C273.247 218.288 282.666 217.669 291.429 220.302C300.191 222.934 307.71 228.641 312.602 236.372C316.45 242.463 318.492 249.521 318.491 256.725V256.725Z'
          fill='#435bc8'
        />
        <path
          d='M312.602 236.372L275.098 273.87C272.74 270.221 259.608 253.261 259.608 253.261C261.291 251.56 263.087 249.975 264.984 248.516L275.873 263.036L308.21 230.699C309.85 232.447 311.321 234.347 312.602 236.372Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='847.02' height='535.575' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
