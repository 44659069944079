import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { NNU } from '../../helpers';
import ReactMarkdown from 'react-markdown';
import { TeamSectionQuery } from '../../../graphqlTypes';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useInView } from 'react-intersection-observer';

import './team.scss';

export default React.forwardRef<HTMLElement | null>(function Team(_, scrollSpyForwardRef) {
  const [inViewRef, inView] = useInView({ triggerOnce: true });
  const data = useStaticQuery<TeamSectionQuery>(graphql`
    query TeamSection {
      allStrapiTeamSection {
        edges {
          node {
            locale
            title
            content
          }
        }
      }
      allStrapiTeamMember {
        edges {
          node {
            name
            positionTags
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 128, layout: FIXED)
                }
              }
            }
            teamMemberDetails {
              locale
              position
              experience
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const localeTeamSectionData = NNU(data.allStrapiTeamSection.edges.find(e => e.node.locale === intl.locale)).node;

  return (
    <section id='team' ref={scrollSpyForwardRef}>
      <h2 ref={inViewRef}>{localeTeamSectionData.title}</h2>
      <ReactMarkdown className='description team-container-description'>{NNU(localeTeamSectionData.content)}</ReactMarkdown>
      {inView ? (
        <motion.div
          className='team-flaminem-container'
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.6,
            duration: 1
          }}
        >
          {data.allStrapiTeamMember.edges.map((teamMember, i) => {
            const localeTeamMemberDetailsData = NNU(teamMember.node.teamMemberDetails?.find(e => e?.locale === intl.locale));
            const tags = NNU(teamMember.node.positionTags?.trim().split(/\s+/));
            return (
              <div key={i} className='card-container'>
                <span className='pro'>{tags[0]}</span>
                <GatsbyImage alt='' image={NNU(teamMember.node.picture?.localFile?.childImageSharp?.gatsbyImageData)} className='team-img' />
                <h3 className='member-title'>{teamMember.node.name}</h3>
                <h6 className='post-title'>{localeTeamMemberDetailsData.position}</h6>
                {localeTeamMemberDetailsData.experience?.split('\n').map((e, j) => (
                  <p key={j} className='post-description'>
                    {e}
                  </p>
                ))}
                <div className='skills'>
                  <ul>
                    {tags.map((t, j) => (
                      <li key={j}>{t}</li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </motion.div>
      ) : (
        <div style={{ height: 840 }} />
      )}
    </section>
  );
});
