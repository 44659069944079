import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import useFetch, { CachePolicies } from 'use-http';
import { ContactSectionQuery } from '../../../graphqlTypes';
import ErrorMessage from './errorForm';
import { NNU } from '../../helpers';
import { useForm } from 'react-hook-form';
import { useIntl } from 'gatsby-plugin-react-intl';
import '../formContact.scss';

type FormData = {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  message: string;
};

interface LayoutProps {
  id: string;
}

export default React.forwardRef<HTMLElement | null, LayoutProps>(function FormContact({ id }: LayoutProps, scrollSpyForwardRef) {
  const data = useStaticQuery<ContactSectionQuery>(graphql`
    query ContactSection {
      allStrapiContactSection {
        edges {
          node {
            locale
            title
            formButtonText
            sentSuccessMessage
            sentErrorMessage
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const itemData = NNU(data.allStrapiContactSection.edges.find(e => e.node.locale === intl.locale)).node;

  const {
    data: messageData,
    loading: messageLoading,
    error: messageError,
    post: sendMessage
  } = useFetch(`${process.env.API_SERVER_URL}/email`, {
    cachePolicy: CachePolicies.NO_CACHE
  });
  const [messageSent, setMessageSent] = React.useState<boolean | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<FormData>();
  const onSubmit = ({ company, firstName, lastName, email, message }: FormData) => {
    sendMessage({
      to: `${process.env.FLAMINEM_CONTACT_EMAIL}`,
      from: 'no-reply@flaminem.com',
      subject: '[Website] New contact message',
      html: `
<pre>
  A person has just completed the contact form.
  
   Last name: ${lastName}
  First name: ${firstName}
     Company: ${company}
      E-mail: ${email}
     Message: ${message.split('\n').join(`\n              `)}
</pre>
  `
    });
  };

  React.useEffect(() => {
    if (messageError) {
      setMessageSent(false);
    } else if (messageData) {
      setMessageSent(true);
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageData, messageError]);

  return (
    <section id={id} ref={scrollSpyForwardRef} className='container m-auto contact-us-container'>
      <h2 className='contact-us-title'>{itemData.title}</h2>
      <form className='form-contact-us' onSubmit={handleSubmit(onSubmit)}>
        <div className='input-container'>
          <input placeholder={intl.formatMessage({ id: 'contactForm.lastName' })} type='text' {...register('lastName', { required: true })} />
          <ErrorMessage error={errors.lastName} />
        </div>
        <div className='input-container'>
          <input placeholder={intl.formatMessage({ id: 'contactForm.firstName' })} type='text' {...register('firstName', { required: true })} />
          <ErrorMessage error={errors.firstName} />
        </div>
        <div className='input-container'>
          <input placeholder={intl.formatMessage({ id: 'contactForm.company' })} {...register('company', { required: true })} />
          <ErrorMessage error={errors.company} />
        </div>
        <div className='input-container'>
          <input placeholder={intl.formatMessage({ id: 'contactForm.email' })} {...register('email', { required: true, pattern: /^\S+@\S+$/i })} />
          <ErrorMessage error={errors.email} />
        </div>
        <div className='input-container '>
          <textarea placeholder={intl.formatMessage({ id: 'contactForm.message' })} {...register('message', { required: true })} />
          <ErrorMessage error={errors.message} />
        </div>

        <div className='button-container'>
          <button className='bg-indigo-600' type='submit' disabled={messageLoading}>
            {itemData.formButtonText}
          </button>
        </div>
        {messageSent === true && <p className='mt-4 italic'>{itemData.sentSuccessMessage}</p>}
        {messageSent === false && <p className='mt-4 italic text-red-500'>{itemData.sentErrorMessage}</p>}
      </form>
    </section>
  );
});
