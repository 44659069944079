import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { motion } from 'framer-motion';
import { NNU } from '../../helpers';
import ReactMarkdown from 'react-markdown';
import { Solution2SectionQuery } from '../../../graphqlTypes';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useInView } from 'react-intersection-observer';

import './solutions2.scss';

export default function Solution2() {
  const [ref, inView] = useInView({ triggerOnce: true });
  const data = useStaticQuery<Solution2SectionQuery>(graphql`
    query Solution2Section {
      allStrapiSolution2Section {
        edges {
          node {
            locale
            title
          }
        }
      }

      allStrapiSolution2Item(sort: { fields: [id] }) {
        edges {
          node {
            locale
            descriptionBullets
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const localeSolution2SectionData = NNU(data.allStrapiSolution2Section.edges.find(e => e.node.locale === intl.locale)).node;
  const localeSolution2ItemData = data.allStrapiSolution2Item.edges.filter(e => e.node.locale === intl.locale).map(e => e.node);

  return (
    <section className='solution-2' ref={ref}>
      <h2>{localeSolution2SectionData.title}</h2>
      {inView ? (
        <div className='solutions-2-containers'>
          {localeSolution2ItemData.map((itemData, i) =>
            i === 0 || i === 3 ? (
              <motion.div
                key={i}
                className={`solutions-2-container solutions-2-container-${i + 1}`}
                initial={{ opacity: 0, x: -150, y: i === 0 ? -50 : 50 }}
                animate={{ opacity: 1, x: 0, y: 0 }}
                transition={{
                  delay: 0.4,
                  duration: 1
                }}
              >
                <div className='solutions-2-index'>{i + 1}</div>
                <ReactMarkdown className='description'>{itemData.descriptionBullets || ''}</ReactMarkdown>
              </motion.div>
            ) : (
              <motion.div
                key={i}
                className={`solutions-2-container solutions-2-container-${i + 1}`}
                initial={{ opacity: 0, x: 150, y: i === 2 ? 50 : -50 }}
                animate={{ opacity: 1, x: 0, y: 0 }}
                transition={{
                  delay: 0.6,
                  duration: 1
                }}
              >
                <div className='solutions-2-index'>{i + 1}</div>
                <ReactMarkdown className='description'>{itemData.descriptionBullets || ''}</ReactMarkdown>
              </motion.div>
            )
          )}
        </div>
      ) : (
        <div style={{ height: 705 }} />
      )}
    </section>
  );
}
